var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { requireGlobalProperty } from "../utils";
import { leftMenuLinksApi } from "./leftMenuLinksApi";
export function leftMenuLinksModule() {
    var _a;
    return {
        id: Symbol('leftMenuLinksModule'),
        reducers: (_a = {},
            _a[leftMenuLinksApi.reducerPath] = leftMenuLinksApi.reducer,
            _a),
        middleware: [
            leftMenuLinksApi.middleware
        ],
        hooks: function (context) { return ({
            sidebarItems: function () {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function () {
                    var vehicle, user, partsCatalogueGroupId, baseSidebarItems, baseSidebarItemsWithPart, data, vin, id;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                vehicle = context.vehicle, user = context.user;
                                partsCatalogueGroupId = requireGlobalProperty('partsCatalogueGroupId');
                                if (!(user === null || user === void 0 ? void 0 : user.accountSetupUrl)) {
                                    return [2 /*return*/, []];
                                }
                                baseSidebarItems = [
                                    {
                                        title: 'Portal',
                                        href: 'https://dealerportal.astonmartin.com'
                                    },
                                ];
                                if (!vehicle) {
                                    return [2 /*return*/, baseSidebarItems];
                                }
                                baseSidebarItemsWithPart = __spreadArray(__spreadArray([], baseSidebarItems, true), [
                                    {
                                        title: context.strings.amtisLinks.parts,
                                        href: global.iwaProperties['itisUrl'] + '/parts/parts-search'
                                    },
                                ], false);
                                return [4 /*yield*/, context.dispatch(leftMenuLinksApi.endpoints.getDocuments.initiate({ vehicle: vehicle, groupId: partsCatalogueGroupId }))];
                            case 1:
                                data = (_c.sent()).data;
                                if (!data) {
                                    return [2 /*return*/, baseSidebarItemsWithPart];
                                }
                                vin = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.ref) === 'vin' && vehicle.vin;
                                if (data.elements.length === 1) {
                                    id = (_a = data.elements[0].match.id) !== null && _a !== void 0 ? _a : (_b = data.elements[0].translations[0]) === null || _b === void 0 ? void 0 : _b.id;
                                    return [2 /*return*/, __spreadArray(__spreadArray([], baseSidebarItemsWithPart, true), [
                                            {
                                                title: "".concat(vehicle.name, " ").concat(context.strings.amtisLinks.partsCatalogue),
                                                href: vin
                                                    ? "/vehicles/".concat(vin, "/documents/").concat(id, "?group=").concat(partsCatalogueGroupId)
                                                    : (vehicle.year
                                                        ? "/products/".concat(vehicle.productVariantId, "/").concat(vehicle.year, "/documents/").concat(id, "?group=").concat(partsCatalogueGroupId)
                                                        : "/products/".concat(vehicle.productVariantId, "/documents/").concat(id, "?group=").concat(partsCatalogueGroupId))
                                            }
                                        ], false)];
                                }
                                return [2 /*return*/, __spreadArray(__spreadArray([], baseSidebarItemsWithPart, true), [
                                        {
                                            title: "".concat(vehicle.name, " ").concat(context.strings.amtisLinks.partsCatalogue),
                                            href: vin
                                                ? "/vehicles/".concat(vin, "/groups/").concat(partsCatalogueGroupId)
                                                : (vehicle.year
                                                    ? "/products/".concat(vehicle.productVariantId, "/").concat(vehicle.year, "/groups/").concat(partsCatalogueGroupId)
                                                    : "/products/".concat(vehicle.productVariantId, "/groups/").concat(partsCatalogueGroupId))
                                        }
                                    ], false)];
                        }
                    });
                });
            }
        }); },
        strings: function (language) {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 4]);
                            return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(language, ".strings.ts"))];
                        case 1: return [2 /*return*/, (_b.sent()).default];
                        case 2:
                            _a = _b.sent();
                            return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ './strings/en.strings')];
                        case 3: return [2 /*return*/, (_b.sent()).default];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    };
}
