export function requireGlobalProperty(key) {
    var property = global.iwaProperties[key];
    if (!property) {
        throw new Error("Global property not set: ".concat(key));
    }
    return property;
}
export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
